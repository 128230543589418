import React from 'react';
import { connect } from 'react-redux';
import { BasePageStyles } from '../../../hooks/styles'
import { Button, ClickAwayListener, Divider, Grow, MenuItem, Menu, MenuList, Paper, Popper, SwipeableDrawer, TextField } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useHistory } from 'react-router-dom';
import { OrderActions } from '../../../redux/actions';
import config from '../../../config';
import { useMediaQuery } from 'react-responsive';
import MenuIcon from '@mui/icons-material/Menu';
import MediaQuery from 'react-responsive';

interface MenuListProps {}

const MenuListComposition = (props: MenuListProps) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 900px)'
  })
  const classes = BasePageStyles();
  const [openDrawer, setOpenOpenDrawer] = React.useState(false);
  const [onProductPage, setOnProductPage] = React.useState(false);
  const [onTrackingPage, setOnTrackingPage] = React.useState(false);
	const history = useHistory(); 

	const routeChange = (path: string) =>{ 
		history.push(path);
	}

  const gotoWPLink = (link: string) => {
    window.location.href = `${config.wordpressUrl}${link}`;
  }

  const [aboutUsAnchorEl, setAboutUsAnchorEl] = React.useState<null | HTMLElement>(null);
  const aboutUsOpen = Boolean(aboutUsAnchorEl);

  const handleAboutUsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAboutUsAnchorEl(event.currentTarget);
  };
  const handleAboutUsClose = () => {
    setAboutUsAnchorEl(null);
  };

  const [resourcesAnchorEl, setResourcesAnchorEl] = React.useState<null | HTMLElement>(null);
  const resourcesOpen = Boolean(resourcesAnchorEl);

  const handleResourcesClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setResourcesAnchorEl(event.currentTarget);
  };
  const handleResourcesClose = () => {
    setResourcesAnchorEl(null);
  };

  React.useEffect(() => {
		// Fake onHover on links when user is on the referenced page
    switch(window.location.pathname){
      case '/':
        setOnProductPage(true)
        setOnTrackingPage(false)
        break
      case '/order':
        setOnProductPage(false)
        setOnTrackingPage(true)
        break
      default:
        setOnProductPage(false)
        setOnTrackingPage(false)
        break
    }
	}, []);

  return (
    <div>
      <MediaQuery minWidth={1150}>
        <div>
          <Button
            onClick={() => window.location.href = `${config.wordpressUrl}/product-details`}
            className={classes.profileMenuBtn}
          >
            <h3>Product Details</h3>
          </Button>

           {/* resources dropdown with associated menu*/}
          <Button
            
            //onClick={() => window.location.href = `${config.wordpressUrl}/about`}
            id="resources-button"
            aria-controls={resourcesOpen ? 'resources-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={resourcesOpen ? 'true' : undefined}
            onMouseOver={handleResourcesClick}
            className={classes.profileMenuBtn}
          >
            <h3>Resources</h3>
            <ArrowDropDownIcon  />
          </Button>
          <Menu
            id="resources-menu"
            anchorEl={resourcesAnchorEl}
            open={resourcesOpen}
            onClose={handleResourcesClose}
            className={classes.dropdownMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: -60,
              horizontal: -10,
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem className={classes.dropdownBtn} onClick={() => {
              gotoWPLink('/resources')
            }}>
              Response &amp; Prevention
            </MenuItem>
            <MenuItem className={classes.dropdownBtn} onClick={() => {
              gotoWPLink('/news')
            }}>
              Opioid Safety News
            </MenuItem>
            <MenuItem className={classes.dropdownBtn} onClick={() => {
              gotoWPLink('/blog')
            }}>
              Blog
            </MenuItem>          
          </Menu>

           {/* about us dropdown with associated menu*/}
          <Button
            
            //onClick={() => window.location.href = `${config.wordpressUrl}/about`}
            id="about-us-button"
            aria-controls={aboutUsOpen ? 'about-us-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={aboutUsOpen ? 'true' : undefined}
            onMouseOver={handleAboutUsClick}
            onClick={() => {
              gotoWPLink('/about')
            }}
            className={classes.profileMenuBtn}
          >
            <h3>About Us</h3>
            <ArrowDropDownIcon  />
          </Button>
          <Menu
            id="about-us-menu"
            anchorEl={aboutUsAnchorEl}
            open={aboutUsOpen}
            onClose={handleAboutUsClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 16,
              horizontal: -10,
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem className={classes.dropdownBtn} onClick={() => {
              gotoWPLink('/about')
            }}>
              About
            </MenuItem>  
            <MenuItem className={classes.dropdownBtn} onClick={() => {
              gotoWPLink('/about/events')
            }}>
              Events
            </MenuItem>        
          </Menu>


          <Button
            onClick={() => routeChange('/')}
            className={onProductPage ? classes.fakeOnHoverNav : classes.profileMenuBtn}
          >
            <h3>Order Now</h3>
          </Button>
          <Button
            onClick={() => routeChange('/order')}
            className={onTrackingPage ? classes.fakeOnHoverNav : classes.profileMenuBtn}
          >
            <h3>Track Order</h3>
          </Button>
        </div>
        </MediaQuery>

      {/* menu drawer for mobile */}
        <MediaQuery maxWidth={1149}>
        <div>
          <MenuIcon onClick={() => setOpenOpenDrawer(!openDrawer)} fontSize='large'/>
          <SwipeableDrawer
            anchor={'top'}
            open={openDrawer}
            onClose={() => setOpenOpenDrawer(false)}
            onOpen={() => setOpenOpenDrawer(true)}
          >
            <MenuList>
              <MenuItem>
                <Button
                  onClick={() => window.location.href = `${config.wordpressUrl}/product-details`}
                  className={classes.profileMenuBtn}
                >
                  <h3>Product Details</h3>
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  onClick={() => window.location.href = `${config.wordpressUrl}/resources`}
                  className={classes.profileMenuBtn}
                >
                  <h3>Resources</h3>
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  onClick={() => window.location.href = `${config.wordpressUrl}/about`}
                  className={classes.profileMenuBtn}
                >
                  <h3>About Us</h3>
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  onClick={() => routeChange('/')}
                  className={classes.profileMenuBtn}
                >
                  <h3>Order Now</h3>
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  onClick={() => routeChange('/order')}
                  className={classes.profileMenuBtn}
                >
                  <h3>Track Order</h3>
                </Button>
              </MenuItem>
            </MenuList>
          </SwipeableDrawer>
        </div>
        </MediaQuery>
    </div>
  );
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(MenuListComposition);


