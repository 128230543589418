import * as React from 'react';
import { BasePageStyles, globalColors } from '../../hooks/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Grid, Paper, Stack, Step, StepIconProps, StepLabel, Stepper, TextField, styled } from '@mui/material';
import moment from 'moment';
import config from '../../config';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { EvaluateExpression } from '../../hooks/functions/EvaluateExpression';
import { FormatCurrency } from '../../hooks/functions/FormatCurrency';
import queryString from 'query-string';

interface OrderProps {
	orderDetails: any[]
	products: any[]
	getOrderDetails: (payload: any) => void
	setSnackbarMessage: (payload: {message: string, type: string}) => void
}

export const OrderComponent: React.FC<OrderProps> = (props) => {
	const classes = BasePageStyles();
	const history = useHistory(); 
	const NARCAN_PRODUCT_ID = 'NAR062702'
	const NARCAN_PRICE = 44.99; //hardcode bc looking up products from DB is deprecated, and we need it to have a different price here than on NWP.com //parseFloat(props.products.find((product: any) => product.id === NARCAN_PRODUCT_ID)?.price || 0);
	const ODR_KIT = config.odrKitProductId
	const ODR_KIT_PRICE = parseFloat(props.products.find((product: any) => product.id === ODR_KIT)?.price || 0);
	const steps = [ 'ORDER RECEIVED', 'PROCESSING', 'SHIPPED'];

	const [inputValue, setInputValue] = React.useState('');

	const [qsOrderNumber, setQsOrderNumber] = React.useState('');

	const { search } = useLocation();
	const values = queryString.parse(search);

	if (values?.orderNumber && !qsOrderNumber) {
		console.log('qsOrderNumer', values?.orderNumber);
		
		setQsOrderNumber(values.orderNumber as string);
		setInputValue(values.orderNumber as string);
	}

	const routeChange = (path: string) =>{ 
		history.push(path);
	}

	const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
		[`&.${stepConnectorClasses.alternativeLabel}`]: {
			top: 22,
		},
		[`&.${stepConnectorClasses.active}`]: {
			[`& .${stepConnectorClasses.line}`]: {
			backgroundImage:
				`linear-gradient( 95deg,${globalColors.ODR_DARK_ORANGE} 0%,${globalColors.ODR_ORANGE} 100%)`,
			},
		},
		[`&.${stepConnectorClasses.completed}`]: {
			[`& .${stepConnectorClasses.line}`]: {
			backgroundImage:
				`linear-gradient( 95deg,${globalColors.ODR_DARK_ORANGE} 0%,${globalColors.ODR_ORANGE} 100%)`,
			},
		},
		[`& .${stepConnectorClasses.line}`]: {
			height: 3,
			border: 0,
			backgroundColor:
			theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
			borderRadius: 1,
		},
	}));

	const ColorlibStepIconRoot = styled('div')<{
		ownerState: { completed?: boolean; active?: boolean };
	}>(({ theme, ownerState }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
		zIndex: 1,
		color: '#fff',
		width: 50,
		height: 50,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
		...(ownerState.active && {
			backgroundImage:
			`linear-gradient( 136deg, ${globalColors.ODR_DARK_ORANGE} 0%, ${globalColors.ODR_DARK_ORANGE} 50%, ${globalColors.ODR_ORANGE} 100%)`,
			boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
		}),
		...(ownerState.completed && {
			backgroundImage:
			`linear-gradient( 136deg, ${globalColors.ODR_DARK_ORANGE} 0%, ${globalColors.ODR_DARK_ORANGE} 50%, ${globalColors.ODR_ORANGE} 100%)`,
		}),
	}));

	function ColorlibStepIcon(props: StepIconProps) {
		const { active, completed, className } = props;
		
		const icons: { [index: string]: React.ReactElement } = {
			1: <CheckCircleOutlineIcon />,
			2: <ReceiptIcon />,
			3: <LocalShippingIcon />,
		};
		
		return (
			<ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
			{icons[String(props.icon)]}
			</ColorlibStepIconRoot>
		);
	}

	const onTrackOrderClicked = () => {
		const filter: any = {where: {}}
		if(config.isAutomatedProcess) {filter.where.orderNumber = inputValue}
		else {filter.where.poNumber = inputValue}
		props.getOrderDetails({callback: () => routeChange(`/order`), filter})
	}

	const calculateFullOrderTotal = () => {
		const totals = props.orderDetails.map((orderLine: any) => {
			// let price = orderLine.productId === ODR_KIT ? ODR_KIT_PRICE : NARCAN_PRICE;

			// if (orderLine.discountCode && orderLine.discountCode?.toUpperCase() === 'FFFFAMILY50' && (orderLine.productId === ODR_KIT)) {
			// 	price = 
			// }

			//return EvaluateExpression(`(${orderLine.quantity} * ${price}) + ${orderLine.tax}`)
			return EvaluateExpression(`${orderLine.productTotal} + ${orderLine.tax}`)
		})

		return EvaluateExpression(totals.join(' + '), 2)
	}

	const getTrackingNumbers = () => {
		const trackingNumbers = new Set<string>()
		props.orderDetails.forEach(orderLine => {
			orderLine.transactions?.forEach((txn: any) => {
				const txnTrackingNumbers: string[] = txn.trackingNumbers.split(',')
				txnTrackingNumbers.forEach(trackingNumber => trackingNumbers.add(trackingNumber))
			})
		})
		return Array.from(trackingNumbers)
	}
	

	const renderOrderDetails = () => {
		return (
			<Grid container item textAlign={'center'} xs={12} md={8}>
				<Grid item xs={12}>
					<h1>Thank you for your order!</h1>
					<h2>{config.isAutomatedProcess ? 
						`An email confirmation has been sent to ${props.orderDetails[0].email}!` : 
						`Thank you for placing your order. A member of our customer support team will promptly contact you to finalize and confirm the details of your order.`
					}</h2>
				</Grid>
				<Grid item xs={12}>
					<Stack sx={{ width: '100%' }} spacing={4}>
						<Stepper alternativeLabel activeStep={getTrackingNumbers().length > 0 ? 2 : 1} connector={<ColorlibConnector />}>
							{steps.map((label) => (
							<Step key={label}>
								<StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
							</Step>
							))}
						</Stepper>
					</Stack>
				</Grid>
				<Grid container justifyContent={'space-evenly'}>
					<Paper className={classes.orderDetailsPaper} elevation={1} component={Grid} container item xs={12} md={5}>
						<Grid item xs={12}><h3>Order Details</h3></Grid>
						<Grid item xs={12}><p>Order Date: {moment(props.orderDetails[0].createdAt).format('MM-DD-YYYY')}</p></Grid>
						<Grid item xs={12}><p>Order Number: {config.isAutomatedProcess ? props.orderDetails[0].orderNumber : props.orderDetails[0].poNumber}</p></Grid>
						<Grid item xs={12}><p>Order Total: ${FormatCurrency( calculateFullOrderTotal() )}</p></Grid>
					</Paper>
					<Paper className={classes.orderDetailsPaper} elevation={1} component={Grid} container item xs={12} md={5}>
						<Grid item xs={12}><h3>Delivery Details</h3></Grid>
						<Grid item xs={12}>
							<p>{props.orderDetails[0].shipName}</p>
							<p>{props.orderDetails[0].shipAddress1}</p>
							<p>{props.orderDetails[0].shipAddress2}</p>
							<p>{props.orderDetails[0].shipCity}, {props.orderDetails[0].shipState} {props.orderDetails[0].shipZip}</p>
						</Grid>
						<Grid item xs={12}>
							{getTrackingNumbers().length > 0 ? 
								<h4><a target="_BLANK" href={`https://www.fedex.com/fedextrack/?trknbr=${getTrackingNumbers().join(',')}`}>Track Shipment</a></h4>
							:
								<h4>You’ll receive an email with tracking information when your order ships.</h4>
							}
						</Grid>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<h2>Thank you for choosing ODRescue&trade;! {getTrackingNumbers().length === 0 && `Please allow 3-5 business days for delivery.`}</h2>
				</Grid>
				<Grid item xs={12} >
					<Button variant="contained" size='large'
						onClick={() => window.location.href = `${config.wordpressUrl}/`}
					>Learn How to Administer NARCAN&reg;</Button>
				</Grid>
			</Grid>
		)
	}

	const renderTrackingForm = () => {
		return (
			<Grid container item textAlign={'center'} xs={12} md={8}>
				<Grid item xs={12}>
					<h1>Track Your Order</h1><br/>
				</Grid>
				<Grid item xs={12}>
					<form className={classes.trackingForm} noValidate autoComplete="off">
						<TextField className={classes.trackingInput} id="outlined-basic" label="Order Number" variant="outlined" 
							value={inputValue} 
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								setInputValue(event.target.value);
							}}
						/>
						<Button
							onClick={onTrackOrderClicked}
							className={classes.trackingInput}
							variant="contained"
							size='large'
						>Track</Button>
					</form>
				</Grid>
			</Grid>
		)
	}

	return <>{props.orderDetails[0]?.orderNumber ? renderOrderDetails() : renderTrackingForm()}</>
}