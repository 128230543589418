import { makeStyles } from '@mui/styles';
import { globalColors } from './muiTheme';

const drawerWidth = 240;

export const BasePageStyles = makeStyles((theme) => (

  {
    //top-level customizations for the entire app can be made here
    //this is useful for 3rd party non-MUI components we lack internal control over,
    // eg Highcharts
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      maxWidth: '100vw',
      color: theme.palette.text.primary,
    },
    pageTitle: {
      color: theme.palette.text.secondary
    },
    toolbar: {
      height: '8vh',
      display: 'flex',
      justifyContent: 'space-evenly',
    },
    profileMenuBtn: {
      backgroundColor: 'inherit !important',
      color: globalColors.BLACK,
      fontFamily: '"Jost", sans-serif',
      fontSize: '18px',
      fontWeight: 200,
      padding: '13px 20px',
      '&:hover':{
        color: globalColors.ODR_ORANGE,
        textDecoration: 'underline',
        textDecorationColor: '#ED1C24',
        textDecorationThickness: '3px',
        textUnderlineOffset: '13px'
      }
    },
    fakeOnHoverNav: {
      backgroundColor: 'inherit !important',
      fontFamily: '"Jost", sans-serif',
      fontSize: '18px',
      fontWeight: 200,
      padding: '13px 20px',
      color: globalColors.BLACK,
      textDecoration: 'underline',
      textDecorationColor: '#ED1C24',
      textDecorationThickness: '3px',
      textUnderlineOffset: '13px',
      '&:hover':{
        textDecoration: 'underline',
        textDecorationColor: '#ED1C24',
        textDecorationThickness: '3px',
      }
    },
    profileName: {
      padding: '6px 0 6px 10px',
      color: theme.palette.secondary.main
    },
    appBar: {
      minHeight: '8vh',
      display: 'flex',
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarSpacer: theme.mixins.toolbar,
    appBarBottom: {
      position: "static",
      top: 'auto',
      bottom: 0,
      display: 'flex',
      minHeight: '30vh !important'
    },
    appBarBottomMobile: {
      position: "static",
      top: 'auto',
      bottom: 0,
      display: 'flex',
      minHeight: '30vh !important'
    },
    toolbarMargin: {
      backgroundColor: globalColors.BLACK,
      height: '2vh',
      width: '100%',
    },
    footer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: globalColors.BLACK,
      minHeight: '30vh !important'
    },
    footerMobile: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      paddingBottom: '10px',
      backgroundColor: globalColors.BLACK,
      minHeight: '30vh !important',
      paddingTop: '20px'
    },
    footerLinks: {
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'top', 
      alignItems: 'flex-start'
    },
    footerLinksMobile: {
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'top', 
      alignItems: 'center'
    },
    footerCaptcha: {
      color: globalColors.WHITE,
      textAlign: 'left',
      fontSize: '1em',
      '& a':{
        color: globalColors.ODR_ORANGE,
        paddingLeft: '5px',
        paddingRight: '5px',
        '&:hover':{
          color: globalColors.ODR_DARK_ORANGE
        }
      },
    },
    footerLogo: {
      maxWidth: '220px',
      height: '78px',
      marginBottom: '16px'
    },
    footerLogoMobile: {
      textAlign: 'center',
      maxWidth: '220px',
      height: '78px'
    },
    narcanFooterLogo: {
      maxWidth: '256px',
      height: '74px'
    },
    narcanFooterLogoMobile: {
      textAlign: 'center',
      maxWidth: '256px',
      height: '74px'
    },
    footerLegal: {
      color: 'white', 
      fontFamily: "'Work Sans', sans-serif;", 
      fontWeight: 400, 
      fontSize: '17px', 
      lineHeight: '24px', 
      textAlign: 'left'
    },
    footerLegalFFF: {
      color: '#888', 
      fontFamily: "'Work Sans', sans-serif;", 
      fontSize: '14px', 
      textAlign: 'center'
    },
    content: {
      flexGrow: 1,
      height: '100%',
      paddingBottom: 50,
    },
    container: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(4),
      display: 'flex',
      justifyContent: 'center',
    },
    pageContainer: {
      display: 'flex',
      maxWidth: '75%',
    },
    pageContainerGrid: {
      maxWidth: '100%',
      height: '100%',
    },
    topmenu:{
      width:'100%',
    },
    topmenuTitle:{
      cursor: 'pointer',
      margin: '0px'
    },
    errorAlert: {
      paddingLeft:'24px',
      paddingRight:'24px'
    },
    odRescueLogo:{
      maxWidth: '250px',
      height: '42px',
    },
    trackingForm:{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    trackingInput:{
      margin: '10px 5px 5px 5px'
    },
    orderDetailsPaper: {
      marginTop: '10px',
      '& p':{
        margin: '0px',
        textAlign: 'center',
      },
      '& h3':{
        margin: '10px',
        textAlign: 'center',
      },
      '& h4':{
        textAlign: 'center',
      },
    },
    logoDescription: {
      maxHeight: '100%'
    },
    paymentCard: {
      height: '100%',
      padding: '10px',
      display: 'grid',
      gap: '10px',
      '& h3':{
        margin: '0px',
      },
    },
    paymentCardForm: {
      width: '100%',
      marginTop: '10px',
    },
    paymentDetails: {
      display: 'flex', 
      justifyContent: 'space-evenly',
      flexDirection: 'column',
      alignItems: 'flex-end',
      height: '100%',
      '& ul': {
        listStyle: 'none',
        display: 'table'
      },
      '& li': {
        display: 'table-row'
      },
      '& .paymentDetailsLabel': {
        display: 'table-cell',
        paddingRight: '1em',
        textAlign: 'right'
      },
      '& .paymentDetailsData': {
        display: 'table-cell',
        textAlign: 'right'
      },
      '& h3':{
        margin: '0px',
        textAlign: 'right',
      },
    },
    termsCheck: {
      marginRight: 0,
      fontSize: '1em',
      '& a':{
        color: globalColors.ODR_ORANGE,
        '&:hover':{
          color: globalColors.ODR_DARK_ORANGE
        }
      },
    },
    footerLink:{
      background: `rgba(0, 0, 0, 0) !important`,
      border: 'unset!important',
      color: `${globalColors.WHITE} !important`,
      fontSize: '14px !important', 
      fontWeight: 400, 
      textDecoration: 'none',
      paddingTop: '14px !important',
      paddingBottom: '14px !important',
      paddingLeft: '0px !important',
      justifyContent: 'flex-start',
      '&:hover':{
        background: `rgba(0, 0, 0, 0)!important`,
        color: `${globalColors.ODR_DARK_ORANGE} !important`
      }
    },
    footerSocialLink: {
      cursor: 'pointer', 
      color: 'white',
      margin: '10px'
    },
    baseModal: {
      top: `${50}%`,
      left: `${50}%`,
      transform: `translate(-${50}%, -${50}%)`,
      maxWidth: '600px',
      maxHeight: '100%',
      overflow: 'scroll',
      position: 'absolute',
			width: 'auto',
			backgroundColor: theme.palette.background.paper,
			border: '2px solid #e0e0e0',
			borderRadius: '3px',
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
    },
    validateAddressModal: {
      color: theme.palette.text.primary,
    },
    dropdownMenu: {

    },
    dropdownBtn: {
      padding: '10px 40px',
      fontSize: '22px !important',
      fontWeight: 600,
      '&:hover':{
        color: `#fff`,
        backgroundColor: `#ED1C24`
      }
    }
  }

  ), { index: 1 });
  export default BasePageStyles;
